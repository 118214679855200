/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useState, useEffect } from 'react'
import { Box, BoxProps, Typography, Grid, styled } from '@mui/material'
import { Route } from 'models/microservice'
import { MicroServiceContext } from 'context/Microservice'
import {
  DashboardDownloadButton,
  StyledSnackbar
} from 'components/DashboardDownloadButton'
import ClearIcon from '@mui/icons-material/Clear'
import Feedback from 'components/FeedbackDialog'
import SalesPage from 'pages/HrisDashboard/SalesPage'
import { MOCK_DATA_V1 } from '../../data'
import { DashboardContext } from '../../context/DashboardContext'
import LoadingCircular from '../../components/LoadingCircular'
import { formatData, getLastSyncedDate } from '../../hooks/dataAggregation'
import { HrisData, Record } from '../../models'
import ImageInfoBlock from '../../components/ServerError'
import VizzlyGraphs from './VizzlyGraphs'
import CustomGraphs from './CustomGraphs'

const StyledBox = styled(Box, {
  shouldForwardProp: prop => prop !== 'containerHeight' && prop !== 'enabled'
})<BoxProps>(() => ({
  height: '50vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}))

const HrisHome = () => {
  const { hrisData, isLoading, isFlagEnabled, route, error } =
    useContext(DashboardContext)
  const adminAccess: boolean = isFlagEnabled('enable_admin_dashboard') || false
  const useMockData: boolean = isFlagEnabled('enable_mock_hris_data') || false
  const enableVizzlyGraphs: boolean =
    isFlagEnabled('enable-vizzly-graphs') || false
  const [snackbarOpen, setSnackbarOpen] = useState(false)

  const [hrisUiData, setHrisUiData] = useState<Record[] | null>(null)
  const [lastSyncedDate, setLastSyncedDate] = useState<string | undefined>(
    undefined
  )
  const [integration, setIntegration] = useState<string | undefined>(undefined)
  const [referralUrl, setReferralUrl] = useState<Route>(route)
  const { displayNotification, currentAccount } =
    useContext(MicroServiceContext)

  useEffect(() => {
    if (useMockData) {
      // eslint-disable-next-line no-console
      console.log('Using mock data')
      const mockData: HrisData = formatData(MOCK_DATA_V1)
      setHrisUiData(mockData.employees)
      setLastSyncedDate(getLastSyncedDate(mockData.lastSyncedMathison))
      setIntegration(mockData.integration)
    } else if (!error && hrisData && hrisData.employees) {
      setHrisUiData(hrisData.employees)
      setLastSyncedDate(getLastSyncedDate(hrisData.lastSyncedMathison))
      setIntegration(hrisData.integration)
    }
  }, [hrisData, error, useMockData])

  useEffect(() => {
    const handleReload = () => {
      setReferralUrl({ to: route.to, from: route.to })
      window.location.reload()
    }

    if (referralUrl.from !== route.to) {
      handleReload()
    }
  }, [route, referralUrl])

  if (isLoading || !hrisUiData) return <LoadingCircular containerHeight={565} />

  return (
    <Box>
      {hrisUiData && !isLoading ? (
        <Box>
          {hrisUiData.length > 0 ? (
            <>
              <Box>
                <StyledSnackbar
                  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                  sx={{ padding: '12px 17px', marginTop: '75px' }}
                  open={snackbarOpen}
                  autoHideDuration={3000}
                  onClose={() => setSnackbarOpen(false)}
                >
                  <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                    PDF Downloaded
                    <ClearIcon
                      onClick={() => setSnackbarOpen(false)}
                      sx={{
                        marginLeft: '20px',
                        fontSize: '16px',
                        '&:hover': { cursor: 'pointer' }
                      }}
                    />
                  </Typography>
                </StyledSnackbar>
                <Grid
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start'
                  }}
                >
                  <Box>
                    <Typography variant='h1' marginBottom='24px'>
                      HRIS Dashboard
                    </Typography>
                    <Typography
                      sx={{ paddingLeft: '3px' }}
                      variant='body2'
                      marginBottom='50px'
                    >
                      Last refreshed{' '}
                      <Box sx={{ fontWeight: 'bold' }} component='span'>
                        {`${lastSyncedDate} `}
                      </Box>
                      from{' '}
                      <Box sx={{ fontWeight: 'bold' }} component='span'>
                        {integration}
                      </Box>
                      <Box>
                        <Feedback />
                      </Box>
                    </Typography>
                  </Box>
                  <DashboardDownloadButton setSnackbarOpen={setSnackbarOpen} />
                </Grid>
              </Box>
              <Box>
                {enableVizzlyGraphs ? (
                  <VizzlyGraphs
                    hrisUiData={hrisUiData}
                    adminAccess={adminAccess}
                  />
                ) : (
                  <CustomGraphs hrisUiData={hrisUiData} />
                )}
              </Box>
            </>
          ) : (
            <SalesPage
              delay={1000}
              epName={currentAccount?.employer.name || ''}
              enableVizzlyGraphs={enableVizzlyGraphs}
            />
          )}
        </Box>
      ) : (
        <ImageInfoBlock serverError delay={3000} />
      )}
    </Box>
  )
}

export default HrisHome
