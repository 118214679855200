/* eslint-disable no-console */
import React, { createContext, Context, useMemo } from 'react'
import { HrisData } from '../models'
import useDashboard from '../hooks/useDashboard'
import { CurrentAccount, Route } from '../models/microservice'

export type DashboardContextType = {
  currentAccount: CurrentAccount | undefined
  isFlagEnabled: (flagName: string) => boolean | undefined
  route: Route
  isLoading: boolean
  hrisData: HrisData | undefined | null
  error: any
}

export const DashboardContext: Context<DashboardContextType> =
  createContext<DashboardContextType>({
    currentAccount: undefined,
    isFlagEnabled: () => undefined,
    route: { to: undefined, from: undefined },
    isLoading: true,
    hrisData: undefined,
    error: undefined
  })

export const DashboardProvider = ({ children }: any): JSX.Element => {
  const { currentAccount, isFlagEnabled, isLoading, hrisData, error, route } =
    useDashboard()

  console.log('current account:', JSON.stringify(currentAccount))

  const values = useMemo(
    () => ({
      currentAccount,
      isFlagEnabled,
      route,
      isLoading,
      hrisData,
      error
    }),
    [currentAccount, isFlagEnabled, route, isLoading, hrisData, error]
  )

  return (
    <DashboardContext.Provider value={values}>
      {children}
    </DashboardContext.Provider>
  )
}
