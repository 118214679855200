/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react'
import { useQuery, gql } from '@apollo/client'
import { MicroServiceContext } from '../context/Microservice'
import { formatData } from './dataAggregation'
import { HrisData } from '../models'

// Queries
const GET_HRIS_DATA = gql`
  query GetHRIS {
    hrisRepresentations {
      employerId
      employeeId
      employmentStatus
      department
      age
      employmentType
      ethnicity
      gender
      tenureYears
      veteranStatus
      disabledStatus
      lastSyncedMathison
      integration
      groupType
    }
  }
`

// Hooks
const useDashboard = () => {
  const nullData: HrisData = {
    employerId: '',
    lastSyncedMathison: '',
    integration: '',
    employees: []
  }
  const { maestroClient, currentAccount, isFlagEnabled, route } =
    useContext(MicroServiceContext)
  const [hrisData, setHrisData] = useState<HrisData>(nullData)

  const { data, loading, error } = useQuery(GET_HRIS_DATA, {
    client: maestroClient
  })

  console.log('current account:', JSON.stringify(currentAccount))

  useEffect(() => {
    if (data) {
      const uiData: HrisData | undefined = formatData(data.hrisRepresentations)
      setHrisData(uiData)
    }
  }, [data])
  return {
    currentAccount,
    hrisData,
    isFlagEnabled,
    route,
    isLoading: loading,
    error
  }
}
export default useDashboard
